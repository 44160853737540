.inputBox {
  display: flex;
  align-items: center;
  padding: 16px;
  box-shadow: 0px -12px 10px rgba(30, 83, 133, 0.03);
  &.mobileStyle {
    padding: 13px 15px;
    .auditBox {
      margin-right: 10px;
    }
  }
}
.auditBox {
  cursor: pointer;
  margin-right: 20px;
  width: 30px;
  height: 30px;
}
.operationContainer {
  padding: 24px;
  //background: #fafafa;
  background: #5F5F5F;
}
.operation {
  display: flex;
}
.operaItem {
  cursor: pointer;
  display: flex;
  margin-right: 70px;
  flex-direction: column;
  align-items: center;
  font-family: 'Helvetica Neue', sans-serif;
}
.iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 10px;
}
.title {
  padding: 18px 0;
  color: #1f2128;
  font-weight: bold;
  font-size: 12px;
}

.warning {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 10px;
  color: #bdbdbd;
  .icon {
    margin-right: 5px;
  }
}
.close {
  transform: rotateZ(45deg);
}
.transferContainer {
  img {
    max-width: 600px;
  }
}
